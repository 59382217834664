import { useMemo } from 'react';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import { ListBox, LoadingListBox } from './form/list-box';
import { yup } from '../utils';
import { usePractitionersState } from '../api/store/practitioners';
import { PractitionerAvatar } from './avatar';

export function PractitionerListBox({ field, ...props }) {
  const state = usePractitionersState();
  const options = useMemo(() => makePractitionerListBoxOptions(state.data), [state.data]);
  return state.suspend ? (
    <LoadingListBox field={field} />
  ) : (
    <ListBox
      {...props}
      field={field}
      options={options}
      placeholder="Select practitioner"
      SelectedComponent={SelectedPractitioners}
    />
  );
}

PractitionerListBox.propTypes = {
  field: yup.string().required().pt(),
};

function SelectedPractitioners({ value: values, options }) {
  return useMemo(() => {
    const selectedOptions = values ? options.filter((x) => values.includes(x.key)) : [];
    if (selectedOptions.length === options.length || selectedOptions.length === 0) {
      return <ListBox.LabelItem key="all" label="All" icon={UserGroupIcon} />;
    } else if (selectedOptions.length === 1) {
      return selectedOptions[0];
    } else {
      const label = `Multiple (${selectedOptions.length}/${options.length})`;
      return <ListBox.LabelItem key={label} label={label} icon={UserGroupIcon} />;
    }
  }, [options, values]);
}

function makePractitionerListBoxOptions(practitioners) {
  return Object.values(practitioners)
    .toSorted((a, b) => a.nameText.localeCompare(b.nameText))
    .map((practitioner) => (
      <ListBox.Item key={practitioner.id}>
        <div className="flex flex-row items-center ui-selected:font-bold">
          <PractitionerAvatar className="size-5" practitioner={practitioner} />
          <span className="ml-2 text-gray-900">{practitioner.nameText}</span>
        </div>
      </ListBox.Item>
    ));
}
