import { cloneElement, createElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { yup } from '../utils';

const BUTTON_COLOURS = {
  gray: {
    filled: 'bg-gray-600 border-gray-600 text-white hover:bg-gray-500',
    outline: 'border-gray-300 bg-white hover:bg-gray-100 text-gray-600',
    plain: 'hover:bg-gray-100 text-gray-600',
  },
  indigo: {
    filled: 'bg-indigo-600 border-indigo-600 text-white hover:bg-indigo-500',
    outline: 'border-indigo-300 bg-white hover:bg-indigo-100 text-indigo-600',
    plain: 'hover:bg-indigo-100 text-indigo-600',
  },
  red: {
    filled: 'bg-red-600 border-red-600 text-white hover:bg-red-500',
    outline: 'border-red-300 bg-white hover:bg-red-100 text-red-600',
    plain: 'hover:bg-red-100 text-red-600',
  },
};

const BUTTON_VARIANTS = {
  filled: 'disabled:bg-gray-300 disabled:border-gray-300 disabled:text-white',
  outline: 'shadow-sm border disabled:bg-gray-300 disabled:border-gray-300 disabled:text-white',
  plain: 'border-0 bg-transparent  disabled:text-gray-400',
};

export function Button({
  variant = 'filled',
  color = 'indigo',
  to,
  label,
  icon,
  className,
  labelClassName,
  size = 'normal',
  type = 'button',
  form,
  onClick,
  disabled = false,
  testId,
  ref,
}) {
  const { buttonStyle, iconStyle } = useMemo(() => {
    let buttonStyles = [
      'box-border inline-block rounded capitalize leading-normal transition duration-250 ease-in-out disabled:pointer-events-none focus:outline-none focus:ring-0 flex flex-nowrap items-center justify-center whitespace-nowrap focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
    ];
    let iconStyles = ['inline-block last:mr-0'];

    buttonStyles.push(BUTTON_VARIANTS[variant]);
    buttonStyles.push(BUTTON_COLOURS[color][variant]);

    switch (size) {
      case 'large':
        buttonStyles.push('h-11 px-4 text-base space-x-3');
        iconStyles.push('w-7 h-7');
        break;
      case 'normal':
      default:
        buttonStyles.push('h-9 px-3 text-sm space-x-2');
        iconStyles.push('w-5 h-5');
        break;
    }

    return {
      buttonStyle: clsx(buttonStyles, className),
      iconStyle: clsx(iconStyles),
    };
  }, [className, color, size, variant]);

  const children = useMemo(
    () => (
      <>
        {icon
          ? createElement(icon, {
              className: iconStyle,
              'aria-hidden': true,
            })
          : null}
        <span className={clsx(labelClassName, 'font-semibold whitespace-nowrap')}>{label}</span>
      </>
    ),
    [icon, iconStyle, labelClassName, label],
  );

  return to?.length > 0 ? (
    <Link to={to} onClick={onClick} className={buttonStyle} ref={ref} data-testid={testId}>
      {children}
    </Link>
  ) : (
    <button
      type={type}
      form={form}
      onClick={onClick}
      className={buttonStyle}
      disabled={disabled}
      ref={ref}
      data-testid={testId}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  variant: yup.mixed().oneOf(['outline', 'filled', 'plain']).pt(),
  color: yup.mixed().oneOf(['indigo', 'gray']).pt(),
  to: yup.string().required().pt(),
  label: yup.string().required().pt(),
  icon: yup.mixed().react().optional().pt(),
  size: yup.string().oneOf(['normal', 'large']).pt(),
  className: yup.string().nullable().pt(),
  labelClassName: yup.string().pt(),
  type: yup.string().oneOf(['button', 'reset', 'submit']).pt(),
  form: yup.string().pt(),
  onClick: yup.mixed().callback().pt(),
  disabled: yup.boolean().pt(),
  ref: yup.object().pt(),
  testId: yup.string().pt(),
};

export function ButtonGroup({ variant, color, children }) {
  const buttons = useMemo(() => {
    let style;
    switch (variant) {
      case 'outline': {
        break;
      }
      case 'filled':
      default: {
        style = 'border-white/25 last:border-r-indigo-600';
        break;
      }
    }
    return children
      .filter((x) => x.type === Button)
      .map((x, index) =>
        cloneElement(x, {
          key: index,
          variant,
          color,
          className: clsx(
            x.props.className,
            'rounded-none border-l-0 border-r first:border-l first:rounded-l last:rounded-r px-1',
            style,
          ),
        }),
      );
  }, [children, color, variant]);
  return (
    <div className="box-border inline-flex flex-nowrap, items-center justify-center" role="group">
      {buttons}
    </div>
  );
}

ButtonGroup.propTypes = {
  variant: Button.propTypes.variant,
  color: Button.propTypes.color,
  children: yup.mixed().react().required().pt(),
};
