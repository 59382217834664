import { useMemo } from 'react';
import stringify from 'safe-stable-stringify';
import { yup, urlJoin } from '../../../lib/utils';
import { DateTime } from '../../../lib/components/date-time';
import { useLists } from '../../../lib/api/store/calendar';
import { Link, useSearchParams } from 'react-router-dom';
import { PractitionerAvatar } from '../../../lib/components/avatar';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/24/solid';
import { ListScoreBadge } from '../../../lib/components/list-score-badge';
import { useProcedureCode } from '../../../lib/api/store/procedures';
import { DATE_SCHEMA, LIST_SCHEMA, ROOM_SCHEMA, SLOT_SCHEMA } from '../../../lib/api/schemas';
import clsx from 'clsx';

export function DayBlock({ t }) {
  const lists = useLists(t);
  const isEmpty = Object.keys(lists.data?.rooms ?? {}).length === 0;
  return lists.suspend ? (
    <DayBlockLoading t={t} />
  ) : isEmpty ? (
    <DayBlockEmpty t={t} />
  ) : (
    <DayBlockLoaded t={t} lists={lists.data} />
  );
}

function DayBlockLoading({ t }) {
  // TODO: estimate width when loaded to avoid layout shift
  return (
    <div className="h-full w-full col-span-10 border-gray-300 border border-dashed rounded-md p-2 bg-white">
      <DateTime t={t} format="ddd, MMM D" className="block text-gray-400 text-center mb-3" />
    </div>
  );
}

function DayBlockEmpty({ t }) {
  const dow = t.day();
  return dow === 0 || dow === 6 ? (
    <div className="h-full w-full col-span-2"></div>
  ) : (
    <div className="h-full rounded-md p-2 bg-gray-300 text-gray-800 col-span-10">
      <div>
        <DateTime t={t} format="ddd, MMM D" className="block text-center mb-3" />
      </div>
    </div>
  );
}

function DayBlockLoaded({ t, lists }) {
  const rooms = Object.values(lists.rooms);
  const numberOfRooms = rooms.length ?? 0;

  const styles = useMemo(() => {
    return {
      outer: { gridColumnStart: `span ${numberOfRooms * 5}` },
      inner: {
        gridTemplateColumns: `repeat(${numberOfRooms}, minmax(0, 1fr))`,
        gridTemplateRows: 'auto 0 0 0',
      },
    };
  }, [numberOfRooms]);

  const elements = useMemo(() => {
    const sorted = rooms.toSorted((a, b) => a.shortDescription.localeCompare(b.shortDescription));
    return sorted.map((room) => <RoomBlock key={room.id} t={t} room={room} />);
  }, [rooms, t]);

  return (
    <div className="h-full rounded-md p-2 bg-gray-300  text-gray-800" style={styles.outer}>
      <div>
        <DateTime t={t} format="ddd, MMM D" className="block text-center mb-3" />
        <div className="max-w-full w-full grid gap-x-2" style={styles.inner}>
          {elements}
        </div>
      </div>
    </div>
  );
}

function RoomBlock({ t, room }) {
  const alldayList = room.lists.allday;
  const morningList = room.lists.morning;
  const afternoonList = room.lists.afternoon;
  if (alldayList) {
    return (
      <div className="max-w-full w-full bg-gray-100 rounded-md p-1">
        <h2 className="mt-1 mb-2 text-center font-medium text-gray-600">{room.shortDescription}</h2>
        <ListBlock t={t} list={alldayList} />
      </div>
    );
  } else {
    return (
      <div className="max-w-full w-full bg-gray-100 rounded-md p-1">
        <h2 className="mt-1 mb-2 text-center font-medium text-gray-700">{room.shortDescription}</h2>
        {morningList ? <ListBlock t={t} list={morningList} /> : <EmptyList />}
        <div className="block mt-4 pb-4 border-t border-dashed border-gray-700"></div>
        {afternoonList ? <ListBlock t={t} list={afternoonList} /> : <EmptyList />}
      </div>
    );
  }
}

function useShouldShowList(list) {
  const [searchParams] = useSearchParams();
  const filteredPractitioners = searchParams.getAll('practitioner');
  return useMemo(() => {
    const practitioner = list.practitioner.id;
    return filteredPractitioners.length === 0 || filteredPractitioners.includes(practitioner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringify(filteredPractitioners), list.practitioner.id]);
}

function EmptyList() {
  return (
    <div>
      <div className="flex flex-col flex-nowrap items-center py-3 px-1">
        <div className="size-9"></div>
        <h3 className="truncate max-w-full text-sm font-medium">&nbsp;</h3>
      </div>
      <div className="flex flex-col flex-nowrap items-stretch space-y-1">
        <div className="p-1 text-xs w-full">&nbsp;</div>
        <div className="p-1 text-xs w-full">&nbsp;</div>
        <div className="p-1 text-xs w-full">&nbsp;</div>
        <div className="p-1 text-xs w-full">&nbsp;</div>
        <div className="p-1 text-xs w-full">&nbsp;</div>
        <div className="p-1 text-xs w-full">&nbsp;</div>
        <div className="p-1 text-xs w-full">&nbsp;</div>
        <div className="p-1 text-xs w-full">&nbsp;</div>
      </div>
      <div className="size-9"></div>
    </div>
  );
}

function ListBlock({ list }) {
  const [params] = useSearchParams();
  const path = `details/${list.id}`;
  const show = useShouldShowList(list);
  const slots = useMemo(() => {
    const sorted = list.calendarSlots.toSorted(
      (a, b) => a.procedureSlotNumber - b.procedureSlotNumber,
    );
    return sorted.map((slot) => <ListSlot key={slot.id} slot={slot} />);
  }, [list]);
  const isWebpasSynced = list?.syncedSlots === list?.filledSlots;

  return list ? (
    <Link
      to={urlJoin(path, params)}
      className={clsx(
        'block bg-white rounded-md p-1 flex flex-col flex-nowrap items-stretch p-1 cursor-pointer',
        { invisible: !show, 'border-2 border-red-600': !isWebpasSynced },
      )}
    >
      <div className="flex flex-col flex-nowrap items-center py-3 px-1">
        <div className="flex flex-row items-center space-x-2 pb-1">
          <PractitionerAvatar practitioner={list.practitioner} className="size-9" />
          <ListTypeIcon list={list} />
        </div>
        <h3 className="truncate max-w-full font-medium text-gray-700">
          {list.practitioner.nameText}
        </h3>
      </div>
      <div className="flex flex-col flex-nowrap items-stretch">{slots}</div>
      <div className="self-center mt-2 mb-1 flex flex-nowrap space-x-2">
        <ListScoreBadge list={list} />
        {!isWebpasSynced ? <ArrowPathIcon className="size-5 text-error-700" /> : null}
      </div>
    </Link>
  ) : (
    <EmptyList />
  );
}

function ListSlot({ slot }) {
  if (slot.appointment) {
    return <FilledListSlot slot={slot} />;
  } else if (slot.status === 'Unavailable') {
    return <UnavailableListSlot slot={slot} />;
  } else {
    return <AvailableListSlot slot={slot} />;
  }
}

const ADDED_SLOT_STYLE = 'border-orange-400 border border-dashed';

function FilledListSlot({ slot }) {
  let style = 'shaded-gray text-gray-400';
  if (slot.appointment.status === 'Proposed') {
    style = 'shaded-pink';
  } else if (slot.appointment.status === 'Pending') {
    style = 'shaded-orange';
  } else if (slot.isManualEntry) {
    style = clsx(style, ADDED_SLOT_STYLE);
  }
  const procedure = useProcedureCode(slot.appointment.procedure?.procedureCode?.id);
  return (
    <div className={clsx(style, 'text-center mb-1 p-1 last:mb-0 text-xs w-full')}>
      {procedure?.procedureCategory?.shortDisplay ?? <span>&nbsp;</span>}
    </div>
  );
}

function UnavailableListSlot({ slot }) {
  return (
    <div
      className={clsx(
        'text-center mb-1 p-1 last:mb-0 text-xs w-full text-gray-400 line-through striped-gray-bg',
        { ADDED_SLOT_STYLE: slot.isManualEntry },
      )}
    >
      <DateTime t={slot.arrivalTimeTimestamp} format="HH:mm" />
    </div>
  );
}

function AvailableListSlot({ slot }) {
  return (
    <div
      className={clsx('text-center mb-1 p-1 last:mb-0 text-xs w-full shaded-red', {
        ADDED_SLOT_STYLE: slot.isManualEntry,
      })}
    >
      <DateTime t={slot.arrivalTimeTimestamp} format="HH:mm" />
    </div>
  );
}

function ListTypeIcon({ list }) {
  switch (list.listType) {
    case 'Propofol':
      return <StarIcon className="text-sky-400 size-6" />;
    default:
      return null;
  }
}

DayBlock.propTypes = {
  t: yup.mixed().dayjs().required().pt(),
};

DayBlockEmpty.propTypes = {
  t: DayBlock.propTypes.t,
};

DayBlockLoading.propTypes = {
  t: DayBlock.propTypes.t,
};

DayBlockLoaded.propTypes = {
  t: DayBlock.propTypes.t,
  lists: yup
    .object({
      date: DATE_SCHEMA.required(),
      rooms: yup.object({}).required(),
    })
    .required()
    .pt(),
};

RoomBlock.propTypes = {
  t: DayBlock.propTypes.t,
  room: ROOM_SCHEMA.required().pt(),
};

ListBlock.propTypes = {
  list: LIST_SCHEMA.required().pt(),
};

ListSlot.propTypes = {
  slot: SLOT_SCHEMA.pt(),
};

FilledListSlot.propTypes = {
  slot: SLOT_SCHEMA.pt(),
};

UnavailableListSlot.propTypes = {
  slot: SLOT_SCHEMA.pt(),
};

AvailableListSlot.propTypes = {
  slot: SLOT_SCHEMA.pt(),
};

ListTypeIcon.propTypes = {
  list: LIST_SCHEMA.required().pt(),
};
